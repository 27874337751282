import React, { useEffect } from 'react'

export default ({ pageContext }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace('/au/');
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  return <></>;
}

/*
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faPlay } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import Hero from '../../components/Hero'
import ModalVideo from '../../components/ModalVideo'
import Link from '../../components/Link'
import FeaturedPlatformPagesSection from '../../components/FeaturedPlatformPagesSection'
import {bookADemoLinkSwitch} from '../../components/lib/bookADemoLinkSwitch'

import '../compare-employsure.scss'

import stars from '../../images/home2022/MyHR_Home_Stars.png'
import capterra from '../../images/home2022/MyHR_Home_Capterra.png'
import google from '../../images/home2022/MyHR_Home_Google.png'

// New
import Logo from '../../components/Logo'
import IconDisciplinary from '../../images/compare-employsure/icon-disciplinary.png';
import IconContract from '../../images/compare-employsure/icon-contract.png';
import IconPieChart from '../../images/compare-employsure/icon-pie-chart.png';
import IconTarget from '../../images/compare-employsure/icon-target.png';
import IconRestructure from '../../images/compare-employsure/icon-restructure.png';
import IconJustice from '../../images/compare-employsure/icon-justice.png';
import IconSupport from '../../images/compare-employsure/icon-support.png';
import IconSoftware from '../../images/compare-employsure/icon-software.png';
import IconCompliant from '../../images/compare-employsure/icon-compliant.png';
import GetStartedBookImage from '../../images/compare-employsure/get-started-demo.jpg';
import GetStartedOnboardingImage from '../../images/compare-employsure/get-started-onboarding.jpg';
import GetStartedSupportImage from '../../images/compare-employsure/get-started-support.jpg';
import bannerImg from '../../images/case-study/Sales_Tile_2022-04.png'
import customerStoryLogo1 from '../../images/features/customer_story_logos/roosters.png'
import imageVid1 from '../../images/features/customer_thumbnails/Roosters.png'

const PAGE_DATA = {
  stats: [
    {
      img: IconDisciplinary,
      title: '100%',
      description: 'Success rate from personal grievance claims'
    },
    {
      img: IconContract,
      title: '500+',
      description: 'Employment Contracts completed weekly'
    },
    {
      img: IconTarget,
      title: '99%',
      description: 'Employment contract accuracy'
    },
    {
      img: IconPieChart,
      title: '70%',
      description: 'Cheaper on average vs. in-house HR'
    },
    {
      img: IconJustice,
      title: '30',
      description: 'Disciplinaries a week'
    },
    {
      img: IconRestructure,
      title: '25',
      description: 'Restructures a week'
    }
  ],
  getStarted: {
    steps: [
      {
        title: 'Book a demo or start a free trial',
        description: 'You can see MyHR in action with a no obligation product demo with one of our HR experts or jump straight in and start a free trial to see how MyHR works for your business!',
        image: GetStartedBookImage
      },
      {
        title: 'Guided customer onboarding',
        description: 'An HR professional will assist you with the onboarding and set up process for your team, providing you with tailored HR documents, fit for purpose and your business.',
        image: GetStartedOnboardingImage
      },
      {
        title: 'Ongoing HR advice & platform support',
        description: 'Our specialist HR support is here to help you through any issue or process with tailored, step-by-step advice, as well as MyHR platform support to keep your business humming.',
        image: GetStartedSupportImage
      }
    ]
  },
  infoTable: [
    {
      label: "HR software & advisory",
      myhr: true,
      others: false,
    },
    {
      label: "Integrated HR & Payroll",
      myhr: true,
      others: false,
    },
    {
      label: "Tailored HR advice",
      myhr: true,
      others: false,
    },
    {
      label: "HR/People process automation",
      myhr: true,
      others: false,
    },
    {
      label: "First response to personal grievances",
      myhr: true,
      others: true,
    },
    {
      label: "Additional charges for \"PG insurance\"",
      myhr: false,
      others: true,
    },
    {
      label: "Lock-in contracts",
      myhr: false,
      others: true,
    },
    {
      label: "Tailored onboarding",
      myhr: true,
      others: false,
    }
  ],
  customerStory:     {
    logo: customerStoryLogo1,
    imageVid: imageVid1,
    vid: {
      url: 'https://www.youtube.com/embed/G84pvPky_Yc',
      channel: 'youtube',
    },
    text: "Our favourite thing about my HR is the support that they've been able to provide us. We needed a platform that met our training and development needs and also our onboarding needs and MyHR was able to deliver that.",
    name: "Samantha Barrett, Group People & Culture Manager, East Group and Sydney Roosters",
    imageClass: ""
  },
}

export default () => {
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  var locale = {}
  locale.value = "en-AU"
  const demoLink = bookADemoLinkSwitch(locale.value)

  const clickCard = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    window.location.href = el.getAttribute("data-href")
  }

  return (
    <Layout className="CompareEmploysurePage" title="Compare MyHR with Employsure" description="MyHR vs. Employsure | See how MyHR compares with Employsure | MyHR is smart HR software, expert HR support, and no long-term contracts." locale="en-AU">
      <Hero>
        <h1>Compare MyHR with Employsure</h1>
        <p>
          With MyHR you’ll get a smart, full-service software solution and <br/>
          dedicated HR advice and support all in one place,<br/>
          without being locked in to a long-term contract.
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a demo
        </Button>

        <Button
          color="outline-cyan"
          href="/au/trial"
          localePrefix={false}
          className="mr-3"
        >
          Start free trial
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row>
            {PAGE_DATA.stats.map((stat, index) => (
              <Col key={index} xs={4} md={4} lg={2}>
                <div className="bannerIcon new">
                  <img src={stat.img} />
                  <span>{stat.title}</span>
                  <p>{stat.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>
          <div className="Content__intro">
            <h2>What makes MyHR different?</h2>
            <p>You wouldn’t settle for an employee who’s not up to scratch, so don’t settle with your HR needs.</p>
          </div>

          <Row className="mb-4 my-md-2">
            <Col md={6}>
              <div className="comparison_difference">
                <img src={IconContract} />
                <h3>Customised documents<br/> & support</h3>
                <p>Your business is unique, so you need unique documents, not generic templates and expensive additional consulting services if you need more. Templates are a good start, but with MyHR you also get customised, personalised documents and document support that suit your business.</p>
              </div>
            </Col>

            <Col md={6}>
              <div className="comparison_difference">
                <img src={IconSupport} />
                <h3>On-call advice for<br/> any HR issues</h3>
                <p>With MyHR you get practical, expert advice from real humans, included in your plan. From day-to-day admin to more complicated situations like personal grievance management, our team provides highly-customised support to minimise legal risk and get the best outcomes. </p>
              </div>
            </Col>


            <Col md={6}>
              <div className="comparison_difference">
                <img src={IconSoftware} />
                <h3>Smart end-to-end HR software</h3>
                <p>We don’t need to partner with a software solution; we are the software solution. From hiring and onboarding through to leave and payroll and everything in between, MyHR is the complete HR software with all the features your business needs to take the stress out of your HR admin.</p>
              </div>
            </Col>


            <Col md={6}>
              <div className="comparison_difference">
                <img src={IconCompliant} />
                <h3>Fixed monthly fee,<br/> no lock-in contract</h3>
                <p>We don’t need to lock you into a long-term contract because we know the value MyHR will bring to your business above any other HR solution. You’ll pay a simple monthly fee that covers the HR services your business needs, without any hidden costs or extras you weren’t expecting. </p>
              </div>
            </Col>

          </Row>
        </Container>
      </Section>


      <Section className="Content greyBack">
        <Container>
          <div className="Content__intro">
            <h2>MyHR vs Employsure</h2>
            <p>See how MyHR’s technology-led, human optimised software and support stacks up against Employsure.</p>
          </div>
        </Container>

        <Container>
        <div className="employsure_comparison_table">
          <table className="table table-striped table-borderless mt-5">
            <thead>
              <tr>
                <th scope="col" className="feature">See how we compare:</th>
                <th scope="col" className="logo"><Logo/></th>
                <th scope="col">Employsure</th>
              </tr>
            </thead>
            <tbody>
              {PAGE_DATA.infoTable.map((info, index) => (
                <tr key={index}>
                  <th scope="row">{info.label}</th>
                  <td>
                    {info.myhr ?
                    <span className="yes"><FontAwesomeIcon icon={faCheck} className="" /></span>
                    :
                    <span className="no"><FontAwesomeIcon icon={faTimes} className="" /></span>
                    }
                  </td>
                  <td>
                    {info.others ?
                    <span className="yes"><FontAwesomeIcon icon={faCheck} className="" /></span>
                    :
                    <span className="no"><FontAwesomeIcon icon={faTimes} className="" /></span>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </Container>
      </Section>

      <Section className="BannerSection py-5 justify-content-center d-flex flex-column align-items-center">
        <h2 className="text-center">See what our loyal customers have to say</h2>
        <Container>
          <Row className="my-5">
            <Col
              md={{ size: 12, offset: 0 }}
              lg={{ size: 6, offset: 0 }}
            >
              <div className="h-100 d-flex align-items-start flex-column justify-content-center">
                <div className="customerStoriesDiv">
                  <div className="d-block d-md-none my-3">
                    <ModalVideo {...PAGE_DATA.customerStory.vid}>
                      {({ toggle }) => (
                        <div
                          className="UntangleHrSection__video"
                          onClick={toggle}
                        >
                          <img src={PAGE_DATA.customerStory.imageVid} alt={'Video Thumbnail'} />
                          <div className="UntangleHrSection__video-play-icon">
                            <FontAwesomeIcon
                              icon={faPlay}
                              className="icon play-icon"
                            />
                          </div>
                        </div>
                      )}
                    </ModalVideo>
                  </div>
                  <h3 className="my-4 pr-0 pr-md-6 quoteText">
                    “{PAGE_DATA.customerStory.text}”
                  </h3>
                  <div className="customerStoryBy">
                    <p>
                      <span>{PAGE_DATA.customerStory.name}</span>
                    </p>
                    <img
                      src={PAGE_DATA.customerStory.logo}
                      className="customerStoryLogo customerStoryLogoSydneyRoosters"
                    alt="Logo" />
                  </div>
                  <Link
                    type="button"
                    color="outline-white"
                    href="/customer-stories"
                    className="customerStoriesBtn"
                    localePrefix={true}
                  >
                    More customer stories
                  </Link>
                  <p>&nbsp;</p>
                </div>
              </div>
            </Col>
            <Col
              className="d-none d-md-block"
              md={{ size: 12 }}
              lg={{ size: 6 }}
            >
              <ModalVideo {...PAGE_DATA.customerStory.vid}>
                {({ toggle }) => (
                  <div className="UntangleHrSection__video" onClick={toggle}>
                    <img src={PAGE_DATA.customerStory.imageVid} alt={'Video Thumbnail'} />
                    <div className="UntangleHrSection__video-play-icon">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="icon play-icon"
                      />
                    </div>
                  </div>
                )}
              </ModalVideo>
            </Col>
          </Row>
          <div className="d-flex align-items-center flex-row justify-content-center">
            <div className="capterra d-flex align-items-center flex-row">
              <img src={capterra} className="logo" />
              <img src={stars} className="stars" />
            </div>
            <div className="google d-flex align-items-center flex-row">
              <img src={google} className="logo" />
              <img src={stars} className="stars" />
            </div>
          </div>
        </Container>
      </Section>

      <FeaturedPlatformPagesSection
        title="Features that support your business and employees at every stage"
      />


      <Section className="GetStartedSection">
        <h2 className="text-center mb-6">It's easy to get started with MyHR</h2>
        <Container>
          <Row className="justify-content-center">
            {PAGE_DATA.getStarted.steps.map((step, index) => (
              <Col sm={12} md={6} lg={4} index={index} className="mb-3 mb-lg-0">
                <div className="card d-flex flex-column justify-content-center h-100">
                  <div className="card-head">
                    {step.image &&
                      <img src={step.image} className="card-image"/>
                    }
                    <div className="card-head-overlay"/>
                    <div className="card-head-step-number">
                      <span>{index + 1}</span>
                    </div>
                  </div>
                  <div className="card-body">
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>

      <Section className="BannerSectionFooter py-md-2 position-relative">
        <Container>
          <a href={demoLink}>
            <Row className="flex-column-reverse flex-lg-row">
              <Col md={{ size: 6 }} lg={{ size: 6 }} className="text-center pr-3 pr-md-3 pl-3 pl-md-6 pt-5">
                <img src={bannerImg} className="bannerImg" alt="Get Started with MyHR" />
              </Col>
              <Col md={{ size: 6 }} lg={{ size: 6 }} className="">
                <div className="h-100 d-flex align-items-center align-items-md-start flex-column justify-content-center text-center text-md-left">
                  <div>
                    <h2 className="mb-2">Make HR easy</h2>
                    <p className="my-4">
                      Experiencing is believing. Book a demo today.
                    </p>
                    <Button
                      color="electric-blue"
                      href={demoLink}
                      className="mr-3"
                    >
                      Book a demo
                    </Button>
                    <Button
                      color="outline"
                      href="/trial"
                      localePrefix={true}
                      className="mr-3"
                    >
                      Start free trial
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </a>
        </Container>
      </Section>
    </Layout>
  )
}
*/